<template>
	<div class="employee">
		<div class="actions">
			<div class="add" @click="openAddDialog()"><div class="el-icon-plus"></div> 添加员工</div>
		</div>
		<div class="search_area">
			<div>
				员工名称：
				<input type="text" placeholder="请输入员工名称" v-model="username">
				<div class="btn" @click="getMemberList">搜索</div>
			</div>
		</div>
		<div class="table_tit">
			<div></div>
			<div>姓名</div>
			<div>账号</div>
			<div>部门名称</div>
			<div>角色名称</div>
			<div>手机号码</div>
			<div>员工状态</div>
			<div>操作</div>
		</div>
		<div v-loading="loading">
			<div class="employee_list" v-if="employee_list.length">
				<div class="item" v-for="(item,index) in employee_list" :key="index">
					<div class="img_area">
						<img :src="$img(item.headimg)" @error="item.headimg = defaultHeadImage" alt="">
					</div>
					<div>{{item.realname}}</div>
					<div>{{item.username}}</div>
					<div>{{item.department_name ? item.department_name : '暂无部门' }}</div>
					<div>{{item.institution_name ? item.institution_name : '暂无角色' }}</div>
					<div>{{item.mobile}}</div>
					<div>{{item.status ? '已启用':'已停用'}}</div>
					<div class="action">
						<div class="btn" @click="changeEmployeeStatus(item.status,item.member_id)">{{item.status ? '停用' : '启用'}}</div>
						<div class="btn" @click="openAddDialog('edit',item)">修改</div>
						<div class="btn" @click="openInputPassword(item.member_id)">重置密码</div>
						<div class="btn" @click="toEmployeeDetail(item.member_id)">查看概况</div>
					</div>
				</div>
			</div>
			<div v-else class="empty_text">暂无员工</div>
			<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
			</div>
		</div>
		<el-dialog :visible.sync="add_dialog" :show-close="false" class="add_dialog">
			<div class="add_content">
				<div class="tit">{{ is_add ? '添加员工':'修改员工信息'}}</div>
				<div class="condition">
					<div class="condition_item" v-if="is_add">
						<div class="left">
							*<div class="name">头像：</div>
						</div>
						<div class="right">
							<el-upload :action="uploadActionUrl" :show-file-list="false" :on-success="handleAvatarSuccess" class="upload-img">
								<div class="img-wrap"><img :src="$img(headimg ? headimg : defaultHeadImage)" /></div>
							</el-upload>
						</div>
					</div>
					<div class="condition_item" v-if="is_add">
						<div class="left">
							*<div class="name">用户名称：</div>
						</div>
						<div class="right">
							<input type="text" placeholder="请输入用户名称" v-model="username">
						</div>
					</div>
					<div class="condition_item">
						<div class="left">
							*<div class="name">真实姓名：</div>
						</div>
						<div class="right">
							<input type="text" placeholder="请输入真实姓名" v-model="realname">
						</div>
					</div>
					<div class="condition_item">
						<div class="left">
							*<div class="name">手机号码：</div>
						</div>
						<div class="right">
							<input type="text" placeholder="请输入手机号码" v-model="mobile">
						</div>
					</div>
					<div class="condition_item" v-if="is_add">
						<div class="left">
							*<div class="name">密码：</div>
						</div>
						<div class="right">
							<el-input placeholder="请输入密码" v-model="password" show-password></el-input>
						</div>
					</div>
					<div class="condition_item">
						<div class="left">
							*<div class="name">所属部门：</div>
						</div>
						<div class="right">
							<el-cascader :show-all-levels="false" v-model="department_id" :options="all_department" :props="{ checkStrictly: true,value:'department_id',label:'department_name',children:'_child',emitPath:false }" placeholder="请选择所属部门"  clearable></el-cascader>
						</div>
					</div>
					<div class="condition_item">
						<div class="left">
							*<div class="name">所属角色：</div>
						</div>
						<div class="right">
							<el-select v-model="institution_id" placeholder="请选择所属角色">
								<el-option v-for="item in all_organization" :key="item.institution_id" :label="item.institution_name" :value="item.institution_id">
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="btn save" @click="addEmployee()">保存</div>
					<div class="btn" @click="add_dialog = false">返回</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="edit_password" :show-close="false" class="add_dialog">
			<div class="add_content">
				<div class="tit">重置密码</div>
				<div class="condition password">
					<div class="condition_item">
						<div class="left">
							*<div class="name">密码：</div>
						</div>
						<div class="right">
							<el-input placeholder="请输入密码" v-model="modify_password" show-password></el-input>
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="btn save" @click="resetPassword()">保存</div>
					<div class="btn" @click="edit_password = false">返回</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Config from '@/utils/config';
	import { employeePageList, changeEmployeeStatus, DepartmentTree, OrganizationList, addEmployee, editEmployee, resetPassword } from '@/api/organization' 
	export default {
		data() {
			return {
				username:'',
				edit_password:false,
				employee_list:[],
				total:0,
				currentPage: 1,
				pageSize: 6,
				loading:true,
				add_dialog:false,
				is_add:true,
				modify_member_id:'',
				search_department_id:'',
				search_institution_id:'',
				department_id:'',
				institution_id:'',
				username:'',
				realname:'',
				mobile:'',
				password:'',
				headimg:'',
				all_department:[],
				all_organization:[],
				uploadActionUrl: Config.baseUrl + '/api/upload/headimg',
				modify_member_id:'',
				modify_password:''
			}
		},
		computed: {
			...mapGetters(['defaultHeadImage'])
		},
		created() {
			this.search_department_id = this.$route.query.department_id;
			this.search_institution_id= this.$route.query.institution_id;
			this.getMemberList();
			this.getDepartmentTree();
			this.getOrganizationList();
		},
		methods: {
			toEmployeeDetail(id){
				this.$router.push('/member/employee-'+id);
			},
			resetPassword(){
				if(!this.modify_password){
					this.$message.error('请输入密码');
					return;
				}
				resetPassword({
					member_id:this.modify_member_id,
					password:this.modify_password
				})
				.then(res=>{
					if(res.code>=0){
						this.$message({message:'密码重置成功'});
						this.edit_password = false;
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			},
			openInputPassword(member_id){
				this.modify_password = '';
				this.modify_member_id = member_id;
				this.edit_password = true;
			},
			addEmployee(){
				var phonereg = /^1[3-9]\d{9}$/;
				if(this.is_add){
					if(!this.headimg){
						this.$message.error('请上传头像');
						return;
					}
					if(!this.username){
						this.$message.error('请填写用户名称');
						return;
					}
				}
				
				if(!this.realname){
					this.$message.error('请填写真实姓名');
					return;
				}
				if(!this.mobile){
					this.$message.error('请填写手机号码');
					return;
				}
				if(!phonereg.test(this.mobile)){
					this.$message.error('手机号码格式不正确');
					return;
				}
				if(this.is_add){
					if(!this.password){
						this.$message.error('请填写密码');
						return;
					}
				}
				if(!this.department_id){
					this.$message.error('请选择所属部门');
					return;
				}
				if(!this.institution_id){
					this.$message.error('请选择所属角色');
					return;
				}
				if(this.is_add){
					addEmployee({
						department_id:this.department_id,
						username:this.username,
						realname:this.realname,
						mobile:this.mobile,
						institution_id:this.institution_id,
						password:this.password,
						headimg:this.headimg
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'添加成功'})
							this.add_dialog = false;
							this.getMemberList();
						}else{
							this.$message({message:res.message})
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}else{
					editEmployee({
						department_id:this.department_id,
						realname:this.realname,
						mobile:this.mobile,
						institution_id:this.institution_id,
						member_id:this.modify_member_id
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'修改成功'})
							this.add_dialog = false;
							this.getMemberList();
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}
				
			},
			getOrganizationList(){
				OrganizationList()
				.then(res=>{
					if(res.code>=0){
						this.all_organization = res.data;
					}
				})
			},
			getDepartmentTree(){
				DepartmentTree()
				.then(res=>{
					if(res.code>=0){
						this.all_department = Object.values(res.data)
					}
				})
			},
			handleAvatarSuccess(res, file) {
				this.headimg = res.data.pic_path;
				// this.headimg = URL.createObjectURL(file.raw);
			},
			openAddDialog(mode='',member=''){
				this.headimg = '';
				this.username = '';
				this.realname = '';
				this.mobile = '';
				this.password = '';
				this.department_id = '';
				this.institution_id = '';
				this.modify_member_id = '';
				this.is_add = true;
				if(mode=='edit'){
					this.is_add = false;
					this.department_id = member.department_id;
					this.realname = member.realname;
					this.mobile = member.mobile;
					this.institution_id = member.institution_id;
					this.modify_member_id = member.member_id;
				}
				this.add_dialog = true;
			},
			changeEmployeeStatus(status,member_id){
				var confirm_text = '';
				if(status){
					confirm_text = '您确定要停用该员工账号吗？'
				}else{
					confirm_text = '您确定要启用该员工账号吗？'
				}
				this.$confirm(confirm_text, "提示", {
				    confirmButtonText: "确定",
				    cancelButtonText: "取消",
				    type: "warning"
				}).then(() => {
					changeEmployeeStatus({
						member_id:member_id,
						status:status ? 0 : 1
					})
					.then(res=>{
						if(res.code>=0){
							if(status){
								this.$message({message:'停用成功'});
								this.getMemberList();
							}else{
								this.$message({message:'启用成功'});
								this.getMemberList();
							}
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				})
				
			},
			handleCurrentPageChange(page) {
			    this.currentPage = page
				this.getDepartmentList();
			},
			handlePageSizeChange(size) {
			    this.pageSize = size
				this.getDepartmentList();
			},
			getMemberList() {
				this.loading = true;
				employeePageList({
					page:this.currentPage,
					page_size:this.pageSize,
					department_id:this.search_department_id,
					institution_id:this.search_institution_id,
					username:this.username
				})
				.then(res=>{
					if(res.code>=0){
						this.employee_list = res.data.list;
						this.total = res.data.count;
						this.loading = false;
					}
				})
			}
		},
	}
</script>

<style lang="scss">
	.employee{
		min-height: 730px;
		box-sizing: border-box;
		background-color: #fff;
		padding: 20px 20px 20px 18px;
		.add_dialog {
			.el-dialog__body{
				padding: 0;
				.add_content{
					padding: 8px 44px 41px;
					.tit{
						color: #111;
						font-size: 16px;
						line-height: 22px;
						font-weight: 600;
						margin-bottom: 24px;
					}
					.condition{
						padding-left: 33px;
						&.password{
							padding-left: 0;
						}
						.condition_item{
							display: flex;
							align-items: center;
							margin-bottom: 20px;
							.left{
								display: flex;
								align-items: center;
								color: #E02020;
								font-size: 12px;
								line-height: 16px;
								min-width: 70px;
								margin-right: 2px;
								justify-content: flex-end;
								.name{
									margin-left: 5px;
									color: #222;
									font-size: 12px;
									line-height: 16px;
								}
							}
							.right{
								flex:1;
								input{
									width: 45%;
									outline: none;
									border: 1px solid #DBDCDC;
									padding: 8px 12px;
									font-size: 12px;
									line-height: 16px;
									color:#333;
									&::placeholder{
										color: #999;
									}
								}
								.upload-img{
									line-height: 1;
									.img-wrap{
										width: 80px;
										height: 80px;
										img{
											display: block;
											border-radius: 50%;
											width: 100%;
											height: 100%;
										}
									}
								}
								.el-input{
									width: 45%;
									input{
										width: 100%;
										height: auto !important;
										border: 1px solid #DBDCDC;
										border-radius: 0;
									}
									.el-input__icon{
										line-height: 34px;
									}
								}
								.el-select{
									line-height: 1;
									border: 1px solid #DBDCDC;
									.el-input{
										width: 100%;
									}
									.el-input__inner{
										width: 100%;
										height: auto !important;
										border-radius: 0;
										border: none;
									}
									.el-input__suffix{
										line-height: 32px;
										.el-input__icon{
											line-height: 1;
										}
									}
								}
								.el-cascader{
									line-height: 1;
									border: 1px solid #DBDCDC;
									.el-input{
										width: 100%;
									}
									.el-input__inner{
										width: 100%;
										height: auto !important;
										border-radius: 0;
										border: none;
									}
									.el-input__suffix{
										line-height: 32px;
										.el-input__icon{
											line-height: 1;
										}
									}
								}
							}
						}
					}
					.btns{
						margin-top: 12px;
						display: flex;
						align-items: center;
						padding-left: 104px;
						.btn{
							cursor: pointer;
							margin-right: 24px;
							color: #333;
							font-size: 14px;
							line-height: 16px;
							padding: 9px 20px;
							border-radius: 2px;
							border: 1px solid #DBDCDC;
							&.save{
								background-color: #007AFF;
								color: #fff;
							}
						}
					}
				}
			}
		}
		.actions{
			display: flex;
			align-items: center;
			margin-bottom: 16px;
			.add{
				cursor: pointer;
				color: #fff;
				font-size: 13px;
				line-height: 18px;
				padding: 6px 13px;
				border-radius: 4px;
				display: flex;
				align-items: center;
				background-color: #00A98F;
				.el-icon-plus{
					font-size: 12px;
					margin-right: 3px;
					line-height: 12px;
				}
			}
		}
		.table_tit{
			background-color: #F7F8FA;
			border-radius: 4px;
			display: flex;
			align-items: center;
			padding: 16px 17px;
			div{
				color: #222;
				font-size: 12px;
				line-height: 16px;
				&:first-child{
					padding-left: 3px;
					width: 30px;
					margin-right: 17px;
				}
				&:nth-child(2){
					width: 13%;
				}
				&:nth-child(3){
					width: 13%;
				}
				&:nth-child(4){
					width: 13%;
				}
				&:nth-child(5){
					width: 13%;
				}
				&:nth-child(6){
					flex: 1;
				}
				&:nth-child(7){
					width: 10%;
				}
				&:nth-child(8){
					width: 20%;
				}
			}
		}
		.employee_list{
			padding: 20px 17px 0;
			.item{
				display: flex;
				align-items: center;
				margin-bottom: 32px;
				.img_area{
					margin-right: 17px;
				}
				>div{
					color: #55555D;
					font-size: 12px;
					line-height: 16px;
					img{
						width: 30px;
						height: 30px;
						display: block;
						border-radius: 50%;
					}
					&:first-child{
						padding-left: 3px;
						min-width: 30px;
						width: 30px;
					}
					&:nth-child(2){
						width: 13%;
					}
					&:nth-child(3){
						width: 13%;
					}
					&:nth-child(4){
						width: 13%;
					}
					&:nth-child(5){
						width: 13%;
					}
					&:nth-child(6){
						flex: 1;
					}
					&:nth-child(7){
						width: 10%;
					}
					&:nth-child(8){
						width: 20%;
					}
				}
				.action{
					display: flex;
					align-items: center;
					white-space: nowrap;
					justify-content: space-between;
					.btn{
						cursor: pointer;
						color: #0B5CFF;
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.search_area{
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		>div{
			display: flex;
			align-items: center;
			margin-bottom: 12px;
		}
		.el-date-editor{
			border: 1px solid #E0E0E0;
			border-radius: 0;
			height: auto;
			padding: 8px 10px;
			.el-range-separator{
				line-height: 20px;
			}
			.el-input__icon{
				line-height: 1;
			}
			.el-range-input{
				border: none;
				color: #333;
				font-size: 14px;
				line-height: 20px;
				padding: 0;
			}
		}
		input{
			border: 1px solid #E0E0E0;
			outline: none;
			color: #333;
			font-size: 14px;
			line-height: 20px;
			padding: 8px 12px;
			margin-right: 12px;
		}
		input::placeholder{
			color: #888;
			font-size: 14px;
			line-height: 20px;
		}
		.btn{
			color: #FFF;
			font-size: 12px;
			line-height: 16px;
			padding: 6px 18px;
			border-radius: 2px;
			background-color: #00A98F;
			margin-left: 10px;
			cursor: pointer;
		}
	}
	.search_area .el-date-editor{
		padding: 2px 12px !important;
	}
	.empty_text{
		text-align: center;
		padding: 10px;
	}
</style>